import {
  AddComponentHandler,
  ElementCategoryData,
  ElementData,
  RemoveComponentHandler,
} from '@wix/platform-editor-sdk';
import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { getContainerRef, isWidgetRef } from './widget';

export interface ElementsPanelData {
  elements: ElementData[];
  categories: ElementCategoryData[];
}

export async function openElementsPanel(params: {
  editorSDK: FlowEditorSDK;
  widgetRef: ComponentRef;
  data: ElementsPanelData;
  onExpandComponent?: (role: string) => void;
  onCollapseComponent?: (role: string) => void;
}) {
  const { editorSDK, widgetRef, data, onExpandComponent, onCollapseComponent } = params;

  const getCollapsedRefComponentByRole = async (role: string) => {
    const widgetRefHost = await getContainerRef(editorSDK, widgetRef);
    const collapsedRefComponents = await editorSDK.components.refComponents.getCollapsedRefComponents('token', {
      componentRef: widgetRefHost,
      // @ts-expect-error temp until types are GAed
      includeInnerCollapsed: true,
    });
    const collapsedRefComponent = collapsedRefComponents.find((comp) => comp.role === role);
    return collapsedRefComponent?.componentRef;
  };

  const addComponentHandler: AddComponentHandler = async ({ role }, compRef) => {
    const containerRef = (await getCollapsedRefComponentByRole(role)) ?? (await getCollapsableComponent(compRef!));

    await editorSDK.components.refComponents.expandReferredComponent('', {
      componentRef: containerRef,
    });
    onExpandComponent?.(role);
    return editorSDK.application.livePreview.refresh('', {
      shouldFetchData: false,
      source: 'AFTER_EXPAND_ELEMENT',
    });
  };

  const removeComponentHandler: RemoveComponentHandler = async (componentRef, { role }) => {
    const collapsableComponent = await getCollapsableComponent(componentRef);

    await editorSDK.components.refComponents.collapseReferredComponent('', {
      componentRef: collapsableComponent,
    });
    onCollapseComponent?.(role);
  };

  const getCollapsableComponent = async (componentRef: ComponentRef) => {
    return (await isWidgetRef(editorSDK, componentRef)) ? getContainerRef(editorSDK, componentRef) : componentRef;
  };

  return editorSDK.editor.openElementsPanel('', {
    widgetRef,
    categoriesData: data.categories,
    elementsData: data.elements,
    addComponentHandler,
    removeComponentHandler,
  });
}
